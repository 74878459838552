import { type FC, type ReactNode, useCallback } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import {
  Box,
  Button,
  ContentArea,
  Dialog,
  DialogActions,
  DialogContent,
  GridContainer,
  GridItem,
  Spacing,
  Typography,
  styled,
} from '@cofenster/web-components';
import { useSafeFileCache } from '../../capture/useSafeFileCache';

type UploadFailureDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  onAction: () => unknown;
  format: VideoFormat;
  content: ReactNode;
  uploadId: string;
  uploadType: string;
};

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.brand.grey200}`,
}));

const noop = () => undefined;

export const UploadFailureDialog: FC<UploadFailureDialogProps> = ({
  isOpen,
  closeDialog,
  onAction,
  content,
  format,
  uploadId,
  uploadType,
}) => {
  const { removeCachedFileSafe } = useSafeFileCache(uploadType);
  const retryUpload = () => {
    onAction();
    closeDialog();
  };

  const onDeleteCachedFile = useCallback(() => {
    removeCachedFileSafe(uploadId);
    closeDialog();
  }, [uploadId, closeDialog, removeCachedFileSafe]);

  return (
    <Dialog
      withCloseButton={false}
      // Force user to close dialog only by choosing delete/retry action
      onClose={noop}
      open={isOpen}
      title="i18n.global.dialog.UploadFailureDialog.title"
    >
      <DialogContent>
        <Spacing bottom={3}>
          <Typography variant="l" component="p">
            i18n.global.dialog.UploadFailureDialog.description
          </Typography>
        </Spacing>
        <ContentArea videoFormat={format}>
          <StyledBox fullHeight backgroundColor="transparent">
            {content}
          </StyledBox>
        </ContentArea>
      </DialogContent>
      <DialogActions>
        <GridContainer spacing={1}>
          <GridItem xs={12} md={6}>
            <Button variant="secondary" onClick={onDeleteCachedFile} fullWidth>
              i18n.global.dialog.UploadFailureDialog.button.delete
            </Button>
          </GridItem>
          <GridItem xs={12} md={6}>
            <Button onClick={retryUpload} fullWidth>
              i18n.global.dialog.UploadFailureDialog.button.retry
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  );
};

import { useFileCache } from '@cofenster/web-components';
import { useCallback, useMemo } from 'react';
import { useTracking } from '../../context/tracking';

export const useSafeFileCache = (type: string) => {
  const { setCachedFile, renameCachedFile, removeCachedFile } = useFileCache(type);
  const tracking = useTracking();

  const setCachedFileSafe = useCallback(
    async (filename: string, blob: Blob, metadata?: Record<string, string | number | boolean>) => {
      try {
        await setCachedFile(filename, blob, metadata);
      } catch (error) {
        tracking.trackEvent({
          event: 'cacheFileError',
          details: {
            error,
            filename,
            blobSize: blob.size,
            method: 'setCachedFile',
          },
        });
      }
    },
    [setCachedFile, tracking]
  );

  const renameCachedFileSafe = useCallback(
    async (filename: string, newName: string, maxAge: number) => {
      try {
        await renameCachedFile(filename, newName, maxAge);
      } catch (error) {
        tracking.trackEvent({
          event: 'cacheFileError',
          details: {
            error,
            filename,
            method: 'renameCachedFile',
          },
        });
      }
    },
    [renameCachedFile, tracking]
  );

  const removeCachedFileSafe = useCallback(
    async (filename: string) => {
      try {
        await removeCachedFile(filename);
      } catch (error) {
        tracking.trackEvent({
          event: 'cacheFileError',
          details: {
            error,
            filename,
            method: 'removeCachedFile',
          },
        });
      }
    },
    [removeCachedFile, tracking]
  );

  return useMemo(
    () => ({
      setCachedFileSafe,
      renameCachedFileSafe,
      removeCachedFileSafe,
    }),
    [setCachedFileSafe, renameCachedFileSafe, removeCachedFileSafe]
  );
};

import { type FC, type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';
import {
  AspectRatioBox,
  type BackgroundEffect,
  Button,
  FramingSuggestions,
  GridItem,
  Icon,
  SUPPORTS_TRANSFORMING_STREAMS,
  preventForwardProps,
  styled,
  useBackgroundEffect,
  useFeatureFlags,
  useI18n,
  useMediaStreamRecorder,
  useMergedMediaStream,
  usePersistedState,
  useSnackbars,
  useUserMediaStream,
} from '@cofenster/web-components';

import { ORIENTATION_REQUIREMENTS, OrientationRestriction } from '../../../../../components/display';
import type { CaptureAssetMetadata } from '../../../../../context/captureAssetFile';
import { useDialogs } from '../../../../../context/dialogs/useDialogs';
import { useTracking } from '../../../../../context/tracking';
import { useNavigateBack } from '../../../../../hooks/useNavigateBack';
import { useOrientation } from '../../../../../hooks/useOrientation';
import { useCaptureAssetLifecycleFlow } from '../../../CaptureAssetLifecycleFlow';
import { MobileCaptureTitleAndInstructions } from '../../../CaptureTitleAndInstructions/MobileCaptureTitleAndInstructions';
import { InstructionsDivider } from '../../../InstructionsDivider';
import { PreviewStream } from '../../CoverVideo';
import { CameraHint } from '../CameraHint';
import { Countdown } from '../Countdown';

import { AUDIO_CONSTRAINTS } from '../../../constants';
import { useSetBackgroundEffectWithTracking } from '../../../useSetBackgroundEffectWithTracking';
import { CameraPermissionError } from './CameraPermissionError';
import { ContributionRequestTitleAndInstructions } from './ContributionRequestTitleAndInstructions';
import { RecordingBarMobile } from './RecordingBarMobile';
import { TopBar } from './TopBar';
import { useFacingMode } from './useFacingMode';

const CameraPreviewBox = styled(
  'div',
  preventForwardProps(['orientation'])
)(({ orientation }: { orientation: 'landscape' | 'portrait' }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  alignItems: orientation === 'portrait' ? 'center' : undefined,
  backgroundColor: 'black',

  'body &': {
    touchAction: 'pan-x pan-y',
  },
}));

const StartRecordingButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export type WebcamRecorderMobileProps = {
  closeRecorder?: () => unknown;
  onRecordingStopped: (recordedFile: { url: string; blob: File }, metadata: CaptureAssetMetadata) => unknown;
  showFramingSuggestions?: boolean;
};

export type RecordingFlowStep =
  | 'instructions_with_preview'
  | 'instructions_while_recording_paused'
  | 'recording_can_start'
  | 'countdown'
  | 'recording'
  | 'recording_paused'
  | 'recording_ended'
  | 'error'
  | 'contribution_request_instructions';

const stepsWithInstructions = ['instructions_with_preview', 'instructions_while_recording_paused'];

const AspectRatio: FC<PropsWithChildren<{ videoFormat: VideoFormat; orientation: 'portrait' | 'landscape' }>> = ({
  children,
  videoFormat,
  orientation,
}) => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const appHeight = () => setInnerHeight(window.innerHeight);

    window.addEventListener('resize', appHeight);
    return () => window.removeEventListener('resize', appHeight);
  }, []);

  if (orientation === 'landscape') {
    return (
      <AspectRatioBox ratio={VIDEO_FORMATS[videoFormat].aspectRatio} height={innerHeight}>
        {children}
      </AspectRatioBox>
    );
  }

  return (
    <AspectRatioBox ratio={VIDEO_FORMATS[videoFormat].aspectRatio} width="100vw">
      {children}
    </AspectRatioBox>
  );
};

export const WebcamRecorderMobile: FC<WebcamRecorderMobileProps> = ({
  closeRecorder,
  onRecordingStopped,
  showFramingSuggestions,
}) => {
  const [currentStep, setCurrentStep] = useState<RecordingFlowStep>('contribution_request_instructions');
  const { hasFeature } = useFeatureFlags();
  const { openDialog, isOpen } = useDialogs();
  const tracking = useTracking();
  const openHowToLookGoodOnCamera = () => {
    openDialog('FilmingTipsDialog', {});
    tracking.trackEvent({ event: 'recordingTipsOpened' });
  };
  const { format: videoFormat } = useCaptureAssetLifecycleFlow();

  const orientation = useOrientation();
  const navigateBack = useNavigateBack();

  const { requestedFacingMode, setCurrentFacingMode, currentFacingMode, flipCamera } = useFacingMode();

  const { translate } = useI18n();

  const startCountDown = useCallback(() => {
    if (currentStep !== 'countdown') setCurrentStep('countdown');
  }, [currentStep]);

  const { value: microphoneStream } = useUserMediaStream(AUDIO_CONSTRAINTS);
  const { value: cameraStream } = useUserMediaStream(
    useMemo(
      () => ({
        video: {
          facingMode: requestedFacingMode,
          height: {
            min: requestedFacingMode === 'user' ? 720 : 1080,
            max: 1920 /* 1 */,
          },
        },
      }),
      [requestedFacingMode]
    )
  );

  const [backgroundEffect, setBackgroundEffect] = usePersistedState<BackgroundEffect>(
    'background-effect',
    'NONE',
    true
  );
  const setBackgroundEffectWithTracking = useSetBackgroundEffectWithTracking(tracking, setBackgroundEffect);

  const { output: videoStream, isInitializing: isBackgroundEffectInitializing } = useBackgroundEffect(
    cameraStream,
    backgroundEffect,
    videoFormat,
    2
  );
  const mediaStream = useMergedMediaStream(videoStream, microphoneStream);
  const mediaStreamRecorder = useMediaStreamRecorder(mediaStream);

  const startRecording = useCallback(() => {
    mediaStreamRecorder?.start();
  }, [mediaStreamRecorder]);
  const stopRecording = useCallback(() => {
    // https://caniuse.com/auxclick
    const isSafari = !('auxclick' in HTMLElement.prototype);
    // https://bugs.webkit.org/show_bug.cgi?id=276536
    if (isSafari) {
      mediaStreamRecorder?.stop();
    }

    if (cameraStream) cameraStream.getTracks().forEach((track) => track.stop());
    if (microphoneStream) microphoneStream.getTracks().forEach((track) => track.stop());
  }, [mediaStreamRecorder, cameraStream, microphoneStream]);
  const cancelRecording = useCallback(() => mediaStreamRecorder?.cancel(), [mediaStreamRecorder]);
  const pauseRecording = useCallback(() => mediaStreamRecorder?.pause(), [mediaStreamRecorder]);
  const resumeRecording = useCallback(() => mediaStreamRecorder?.resume(), [mediaStreamRecorder]);

  useEffect(() => {
    if (mediaStreamRecorder) {
      if (mediaStreamRecorder.state === 'inactive' && mediaStreamRecorder.bytesAvailable > 0) {
        const file = mediaStreamRecorder.asFile(translate('ScenePage.WebcamRecorder.filename'));
        onRecordingStopped(
          {
            url: URL.createObjectURL(file),
            blob: file,
          },
          {
            uploadSource: 'mobile-webcam-recording',
            cameraEnabled: undefined,
            microphoneEnabled: undefined,
            effects: backgroundEffect,
          }
        );
      }
    }
  }, [
    mediaStreamRecorder,
    mediaStreamRecorder?.bytesAvailable,
    mediaStreamRecorder?.state,
    onRecordingStopped,
    translate,
    backgroundEffect,
  ]);

  useEffect(() => {
    if (mediaStream) setCurrentFacingMode(requestedFacingMode);
  }, [mediaStream, requestedFacingMode, setCurrentFacingMode]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to reset the current facing mode, when the requested facing mode changes
  useEffect(() => {
    setCurrentFacingMode(null);
  }, [requestedFacingMode]);

  const onCountdownEnded = useCallback(() => {
    setCurrentStep('recording');
    if (mediaStreamRecorder?.state === 'paused') resumeRecording();
    else startRecording();
  }, [startRecording, resumeRecording, mediaStreamRecorder?.state]);

  const handleCancelRecording = useCallback(() => {
    cancelRecording();
    closeRecorder?.();
    navigateBack();
  }, [cancelRecording, closeRecorder, navigateBack]);

  const recordingBarStartRecording = () => {
    if (currentStep === 'instructions_while_recording_paused') setCurrentStep('countdown');
    else setCurrentStep('recording_can_start');
  };

  const recordingBardPauseRecording = useCallback(() => {
    setCurrentStep('recording_paused');
    pauseRecording();
  }, [pauseRecording]);

  const recordingBarResumeRecording = useCallback(() => {
    setCurrentStep('recording');
    resumeRecording();
  }, [resumeRecording]);

  const recordingBarStopRecording = useCallback(() => {
    if (currentStep === 'countdown') {
      setCurrentStep('recording_ended');
    } else {
      setCurrentStep('recording_ended');
      stopRecording();
    }
  }, [currentStep, stopRecording]);

  const showInstructionsWhileRecording = useCallback(() => {
    if (currentStep === 'recording') recordingBardPauseRecording();
    setCurrentStep('instructions_while_recording_paused');
  }, [currentStep, recordingBardPauseRecording]);

  const onOpenInstructions = useMemo(() => {
    const isBeforeStartedRecording =
      mediaStreamRecorder?.state !== 'paused' && mediaStreamRecorder?.state !== 'recording';
    const isViewingInstructions = currentStep === 'instructions_while_recording_paused';
    if (!isBeforeStartedRecording && !isViewingInstructions) {
      return showInstructionsWhileRecording;
    }
    return undefined;
  }, [mediaStreamRecorder?.state, currentStep, showInstructionsWhileRecording]);

  const onClose = useMemo(() => {
    if (isOpen()) return undefined;
    return () => {
      if (mediaStreamRecorder?.state !== 'recording') {
        setCurrentStep('contribution_request_instructions');
      } else {
        openDialog('DeleteRecordingDialog', { onAction: handleCancelRecording });
      }
    };
  }, [isOpen, mediaStreamRecorder?.state, openDialog, handleCancelRecording]);

  const { openSnackbar } = useSnackbars();
  const pauseRecordingOnWrongOrientation = useCallback(() => {
    if (currentStep === 'recording') {
      openSnackbar({
        variant: 'warning',
        children: 'i18n.ScenePage.WebcamRecorder.orientationMismatchRecordingStoppedWarning',
      });
      setCurrentStep('recording_paused');
      pauseRecording();
    }
  }, [openSnackbar, currentStep, pauseRecording]);

  const [showFraming, setShowFraming] = useState(true);
  const toggleFraming = useCallback(() => {
    setShowFraming((showFraming) => !showFraming);
  }, []);
  const renderFramingUI = currentStep === 'recording_can_start' && showFramingSuggestions;

  if (currentStep === 'contribution_request_instructions') {
    return (
      <ContributionRequestTitleAndInstructions
        goToNextStep={() => setCurrentStep('recording_can_start')}
        fileType="video"
      />
    );
  }

  return (
    <OrientationRestriction
      needs={ORIENTATION_REQUIREMENTS[videoFormat]}
      onOrientationMismatch={pauseRecordingOnWrongOrientation}
    >
      {currentStep !== 'countdown' && (
        <TopBar orientation={orientation} onClose={onClose} onOpenInstructions={onOpenInstructions} />
      )}
      {currentStep !== 'error' ? (
        <CameraPreviewBox orientation={orientation}>
          <AspectRatio videoFormat={videoFormat} orientation={orientation}>
            {mediaStream && currentFacingMode === requestedFacingMode && (
              <PreviewStream stream={videoStream} mirror={currentFacingMode === 'user'} />
            )}

            {currentStep === 'countdown' && (
              <>
                <Countdown onEnd={onCountdownEnded} />
                <CameraHint />
              </>
            )}

            {renderFramingUI && showFraming && currentFacingMode && (
              <FramingSuggestions videoFormat={videoFormat} facingMode={currentFacingMode} />
            )}
          </AspectRatio>
        </CameraPreviewBox>
      ) : (
        <CameraPermissionError />
      )}

      <div hidden={stepsWithInstructions.includes(currentStep)}>
        <RecordingBarMobile
          orientation={orientation}
          status={mediaStreamRecorder?.state ?? 'inactive'}
          start={startCountDown}
          stop={recordingBarStopRecording}
          recordingDuration={mediaStreamRecorder?.timeRecorded}
          flipCamera={flipCamera}
          pauseRecording={recordingBardPauseRecording}
          resumeRecording={recordingBarResumeRecording}
          isReady={!!mediaStream}
          isCountingDown={currentStep === 'countdown'}
          showFraming={showFraming}
          toggleFraming={renderFramingUI ? toggleFraming : undefined}
          backgroundEffect={backgroundEffect}
          onBackgroundEffectSelect={
            SUPPORTS_TRANSFORMING_STREAMS && hasFeature('VIRTUAL_BACKGROUNDS_MOBILE')
              ? setBackgroundEffectWithTracking
              : undefined
          }
          isBackgroundEffectInitializing={isBackgroundEffectInitializing}
        />
      </div>

      {stepsWithInstructions.includes(currentStep) && (
        <MobileCaptureTitleAndInstructions>
          <InstructionsDivider />
          <GridItem xs={12} sm={6} mb={-1}>
            <StartRecordingButton variant="primary" onClick={recordingBarStartRecording} fullWidth>
              {currentStep === 'instructions_with_preview'
                ? 'i18n.ScenePage.InstructionsScreen.mobile.startRecordingButton.start'
                : 'i18n.ScenePage.InstructionsScreen.mobile.startRecordingButton.resume'}
            </StartRecordingButton>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Button
              fullWidth
              variant="secondary"
              onClick={openHowToLookGoodOnCamera}
              startIcon={<Icon type="GraduationCapIcon" size="s" />}
            >
              i18n.ScenePage.InstructionsScreen.mobile.howToLookGoodOnCameraButton
            </Button>
          </GridItem>
        </MobileCaptureTitleAndInstructions>
      )}
    </OrientationRestriction>
  );
};

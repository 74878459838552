import { type FC, memo } from 'react';

import { Icon, NativeVideoPlayer, preventForwardProps, styled, useCachedFile } from '@cofenster/web-components';

import type { ContributionRequestListByActor } from '../../../api/hooks/contributionRequestList/useContributionRequestListByActor';

export const TASK_LINK_CLASSNAME = 'task-link';

type ContributionRequest = ContributionRequestListByActor['contributionRequestList']['requests'][number];
type VideoAsset = ContributionRequest['videoAssets'][number];
type ImageAsset = ContributionRequest['imageAssets'][number];

export const isVideoAsset = (asset: VideoAsset | ImageAsset): asset is VideoAsset => {
  return asset.__typename === 'VideoAsset';
};

const TaskThumbnailContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  flex: '0 0 64px',
  height: 64,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.brand.blue_alpha20,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  overflow: 'hidden',
  // willChange: 'transform' is required for Safari to animate properly image that has borderRadius.
  willChange: 'transform',

  [theme.breakpoints.down('md')]: {
    flex: '0 0 50px',
    height: 50,
  },

  '& > *': {
    transition: 'transform 100ms ease-out',
  },

  [`.${TASK_LINK_CLASSNAME}:hover & > *`]: {
    transform: 'scale(1.2)',
  },
}));

const ImageContainer = styled(
  'div',
  preventForwardProps(['thumbnailSrc'])
)<{ thumbnailSrc?: string | null }>(({ thumbnailSrc }) => ({
  flex: '1 0 100%',
  alignSelf: 'stretch',

  backgroundImage: (thumbnailSrc && `url(${thumbnailSrc})`) ?? undefined,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ScreenSizeAwareIcon = styled(Icon)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: 24,
    height: 24,
    borderRadius: 24 / 2,
  },
  [theme.breakpoints.up('sm')]: {
    width: 16,
    height: 16,
    borderRadius: 16 / 2,
  },
}));

const NoContributionYet: FC<{ type: ContributionRequest['type'] }> = ({ type }) => {
  return (
    <>
      {type === 'VIDEO' && <ScreenSizeAwareIcon color="blue" type="MovieIcon" />}
      {type === 'SCREEN_RECORDING' && <ScreenSizeAwareIcon color="blue" type="ScreenShareIcon" />}
      {type === 'IMAGE' && <ScreenSizeAwareIcon color="blue" type="ImageIcon" />}
    </>
  );
};

const StyledNativeVideoPlayer = styled(NativeVideoPlayer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const AlreadyContributed: FC<{ asset: VideoAsset | ImageAsset; requestId: string }> = ({ asset, requestId }) => {
  const { file: cachedFile } = useCachedFile(isVideoAsset(asset) ? 'video' : 'image', `${requestId}-preview`);
  const thumbnailSrc = isVideoAsset(asset) ? asset.thumbnailUrl : asset.imageUrl;

  // Once transcoding is over we can use thumbnailUrl/imageUrl since we don't need to play a video
  if (thumbnailSrc)
    return <ImageContainer thumbnailSrc={thumbnailSrc} data-testid="asset-thumbnail" data-src={thumbnailSrc} />;

  if (isVideoAsset(asset)) {
    // Transcoding is not yet complete, and thumbnailUrl is unavailable.
    // We display the video player using cachedFile, as it matches the video at `asset.videoUrl`.
    // If cachedFile is unavailable (e.g., due to insufficient disk space), fallback to `asset.videoUrl`
    const videoUrl = cachedFile ? URL.createObjectURL(cachedFile) : asset.videoUrl;
    if (!videoUrl) return null;
    return <StyledNativeVideoPlayer src={videoUrl} controls={false} objectFit="cover" />;
  }

  // No thumbnail, no asset.videoUrl, no cachedFile - nothing to render.
  if (!cachedFile) return null;

  return (
    <ImageContainer
      thumbnailSrc={URL.createObjectURL(cachedFile)}
      data-testid="asset-thumbnail"
      data-src={thumbnailSrc}
    />
  );
};

export const TaskThumbnail: FC<{
  asset?: VideoAsset | ImageAsset;
  type: ContributionRequest['type'];
  requestId: string;
}> = memo(({ asset, type, requestId }) => {
  return (
    <TaskThumbnailContainer>
      {asset ? <AlreadyContributed asset={asset} requestId={requestId} /> : <NoContributionYet type={type} />}
    </TaskThumbnailContainer>
  );
});

import { useContext, useMemo } from 'react';

import type { ContributionRequestList } from '../../api/hooks/contributionRequestList/useContributionRequestListByActor';
import { TasksContext, TasksContributionsContext } from './TaskProgressProvider';

export const useTasksProgress = (list: ContributionRequestList) => {
  const taskIds = useContext(TasksContext);
  const [contributionsByTaskId] = useContext(TasksContributionsContext);

  const backendTaskToContributionIds = list.requests.reduce<Record<string, string[]>>(
    (acc, next) => ({
      ...acc,
      [next.id]: next.contributions
        .map((contribution) => {
          if (contribution.__typename === 'ImageContribution') {
            return contribution.imageAsset.id;
          }
          if (contribution.__typename === 'VideoContribution') {
            return contribution.videoAsset.id;
          }
        })
        .filter((it) => it !== undefined),
    }),
    {}
  );

  return useMemo(() => {
    const totalCount = taskIds.length;
    const doneList = taskIds
      .filter((taskId) => contributionsByTaskId[taskId]?.length)
      .filter((taskId) => {
        const task = list.requests.find((request) => request.id === taskId);

        // The manager may have removed the contribution from the system.
        // In this case, we should also remove the contribution here to ensure
        // that the completed tasks are counted accurately.
        const contributedAssetIds = contributionsByTaskId[taskId]?.filter((it) =>
          backendTaskToContributionIds[taskId]?.includes(it)
        );
        if (!task || !contributedAssetIds?.length) return false;

        const videoAssets = task.videoAssets.filter((asset) => contributedAssetIds.includes(asset.id));
        const imageAssets = task.imageAssets.filter((asset) => contributedAssetIds.includes(asset.id));

        return (
          videoAssets.every((asset) => asset.status !== 'Error') &&
          imageAssets.every((asset) => asset.status !== 'Error')
        );
      });
    const doneCount = doneList.length;
    return { totalCount, doneCount, doneList };
  }, [taskIds, contributionsByTaskId, list, backendTaskToContributionIds]);
};

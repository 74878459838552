import { type Dispatch, type FC, type PropsWithChildren, type SetStateAction, createContext, useMemo } from 'react';

import type { ContributionRequestListByActor } from '../../api/hooks/contributionRequestList/useContributionRequestListByActor';
import { useBrowserStorageState } from '../../hooks/useBrowserStorageState';

type ContributionRequestList = ContributionRequestListByActor['contributionRequestList'];

export const TasksContext = createContext<string[]>(null as never);

export const TasksContributionsContext = createContext<
  [Record<string, string[]>, Dispatch<SetStateAction<Record<string, string[]>>>]
>(null as never);

export const getTaskProgressKey = (contributionRequestListId: string) => `tasks-${contributionRequestListId}`;

const DEFAULT_STATE = {};

export const TaskProgressProvider: FC<PropsWithChildren<{ list: ContributionRequestList; taskIds: string[] }>> = ({
  list,
  taskIds,
  children,
}) => {
  const [contributionIdsByTaskId, setContributionIdsByTaskId] = useBrowserStorageState<Record<string, string[]>>(
    getTaskProgressKey(list.id),
    DEFAULT_STATE
  );

  const context = useMemo(
    () =>
      [contributionIdsByTaskId, setContributionIdsByTaskId] as [
        Record<string, string[]>,
        Dispatch<SetStateAction<Record<string, string[]>>>,
      ],
    [contributionIdsByTaskId, setContributionIdsByTaskId]
  );

  return (
    <TasksContext.Provider value={taskIds}>
      <TasksContributionsContext.Provider value={context}>{children}</TasksContributionsContext.Provider>
    </TasksContext.Provider>
  );
};

import { type FC, memo, useCallback, useEffect, useState } from 'react';

import {
  Button,
  GridContainer,
  GridItem,
  Typography,
  type Upload,
  type UploadType,
  styled,
  useTimer,
  useUpload,
} from '@cofenster/web-components';

import { useTracking } from '../../context/tracking';
import { AssetOrUploadStatus } from '../display';
import { FormatAwareContentArea } from '../layout';

import { useConfirmDialog } from '../../hooks/useConfirmDialog';
import { type CaptureType, useCaptureAssetLifecycleFlow } from './CaptureAssetLifecycleFlow';
import { WithGapContainer } from './Container';
import { RetakeActions } from './RetakeActions';
import { useSafeFileCache } from './useSafeFileCache';

const fileHelper = (uploadType: CaptureType) => {
  const fileType: UploadType = uploadType === 'image' ? 'image' : 'video';
  const sceneType = uploadType === 'image' ? 'image' : uploadType === 'screenRecording' ? 'screen' : 'video';

  return {
    fileType,
    sceneType,
  };
};

const Container = styled(GridContainer)(({ theme }) => ({
  height: '100%',
  borderRadius: theme.shape['borderRadius-l'],
  backgroundColor: theme.palette.brand.linen50,
}));

const useTrackUploadingAssetCancelled = (upload: Upload | undefined) => {
  const timer = useTimer();
  useEffect(() => {
    if (upload) {
      timer.start();
    }
  }, [upload, timer]);

  const tracking = useTracking();
  return useCallback(() => {
    tracking?.trackEvent({
      event: 'uploadAssetCancelled',
      details: { uploadDuration: timer.time, fileSize: upload?.file?.size, fileType: upload?.file?.type },
    });
  }, [tracking, timer, upload?.file?.size, upload?.file?.type]);
};

export const UploadingOrProcessingCaptureAsset: FC<{
  uploadId: string;
  type: CaptureType;
  status?: 'Ready' | 'Waiting' | 'Processing' | 'Error';
}> = memo(({ uploadId, status, type }) => {
  const { fileType, sceneType } = fileHelper(type);
  const { removeCachedFileSafe } = useSafeFileCache(fileType);
  const { getUpload, cancelAndClearUpload: cancelUpload } = useUpload();
  const upload = getUpload(fileType, uploadId);
  const { onDelete } = useCaptureAssetLifecycleFlow();
  const [uploadFileName, setUploadFileName] = useState<string | null>(null);
  const trackUploadingAssetCancelled = useTrackUploadingAssetCancelled(upload);
  const confirmDialog = useConfirmDialog({
    title: 'i18n.global.upload.cancelDialog.title',
    content: 'i18n.global.upload.cancelDialog.content',
    confirm: 'i18n.global.upload.cancelDialog.confirm',
    cancel: 'i18n.global.upload.cancelDialog.cancel',
  });

  const onCancelClicked = useCallback(async () => {
    if (upload) {
      if (await confirmDialog()) {
        await removeCachedFileSafe(uploadId);
        trackUploadingAssetCancelled();
        return cancelUpload(fileType, uploadId);
      }
    } else {
      return onDelete();
    }
  }, [
    upload,
    confirmDialog,
    removeCachedFileSafe,
    trackUploadingAssetCancelled,
    cancelUpload,
    onDelete,
    fileType,
    uploadId,
  ]);

  useEffect(() => {
    const fileName = upload?.file?.name;
    if (fileName) setUploadFileName(fileName);
  }, [upload?.file?.name]);

  return (
    <WithGapContainer>
      <FormatAwareContentArea data-testid="uploading-processing-panel">
        <Container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          m={0}
          width="100%"
          flexWrap="nowrap"
        >
          <GridItem>
            <AssetOrUploadStatus status={status} upload={upload} iconSize="l" iconContainerSize="xxl" />
          </GridItem>
          <GridItem textAlign="center">
            <Typography variant="h4" align="center">
              {uploadFileName}
            </Typography>
          </GridItem>
          <GridItem>
            <Typography>
              {upload
                ? `i18n.ScenePage.UploadingScreen.headline.uploading.${sceneType}`
                : `i18n.ScenePage.UploadingScreen.headline.optimizing.${sceneType}`}
            </Typography>
          </GridItem>
          <GridItem>
            <Button variant="tertiary" onClick={onCancelClicked} data-testid="cancel-button">
              {`i18n.ScenePage.UploadingScreen.cancelUploadOrProcessing.${sceneType}`}
            </Button>
          </GridItem>
        </Container>
      </FormatAwareContentArea>
      {!upload && <RetakeActions />}
    </WithGapContainer>
  );
});
UploadingOrProcessingCaptureAsset.displayName = 'UploadingOrProcessingAsset';
